<template>
  <div class="add-funds-container">
    <!-- https://twitter.com/intent/tweet?text=Get%20a%20cash%20bonus%20to%20invest%20when%20you%20signup%20and%20save%20on%20the%20Cowrywise%20app%20with%20my%20invite%20code%20GIFTVIM%20or%20invite%20link&url=https://get.cowrywise.com/r/GiftVIM -->
    <!-- https://web.facebook.com/sharer/sharer.php?t=Get%20a%20cash%20bonus%20to%20invest%20when%20you%20signup%20and%20save%20on%20the%20Cowrywise%20app%20with%20my%20invite%20code%20GIFTVIM%20or%20invite%20link&u=https%3A%2F%2Fget.cowrywise.com%2Fr%2FGiftVIM&_rdc=1&_rdr -->

    <div class="ref-section">
      <div class="texts">
        <div class="text">
          <span class="ref-text">Total Referalls</span>
          <h2 class="ref-balance">20</h2>
        </div>
        <div class="text">
          <span class="ref-text">Total Earnings</span>

          <h2 class="ref-balance"><sup>₦</sup> 20</h2>
        </div>
        <div class="text">
          <span class="ref-text">Available Earnings</span>
          <h2 class="ref-balance"><sup>₦</sup> 20</h2>
        </div>
      </div>

      <nav class="add-cta">
        <ul class="links">
          <button @click="$refs.shareM.open()" class="add-btn btn">
            <i class="fa-solid fa-share-nodes"></i>
            <span>Share Referal link</span>
          </button>
          <button @click="$refs.modal.open()" class="add-btn btn">
            <i class="fa-solid fa-share"></i>
            <span>Transfer</span>
          </button>
        </ul>
      </nav>
      <div class="actions">
        <sweet-modal ref="modal">
          <sweet-modal-tab title="To balance" id="bal">
            <div class="add-container">
              <div class="method-image"></div>
              <form v-on:submit.prevent>
                <input
                  placeholder="Amount : Min (₦ : 1000)"
                  type="number"
                  class="price-input input"
                />
                <button class="btn">Add to balance</button>
              </form>
            </div>
          </sweet-modal-tab>
          <sweet-modal-tab title="To bank" id="bank">
            <div class="add-container">
              <form v-on:submit.prevent>
                <input
                  placeholder="Amount : Min (₦ : 1000)"
                  type="number"
                  class="price-input input"
                />
                <div class="select-add-section">
                  <select name="ba" class="input">
                    <option value="">Select Bank</option>
                    <option value="">David Enyoghasim - Access Bank</option>
                    <option value="">David Enyoghasim - Citibank</option>
                  </select>
                  <a href="#">Add new account</a>
                </div>
                <button class="btn">Pay</button>
              </form>
            </div></sweet-modal-tab
          >
        </sweet-modal>
        <sweet-modal ref="shareM">
          <div class="share-section">
            <h2 class="get-head">Get Free Cash</h2>
            <p class="share-text">
              You can earn passive income by sharing your link<br />Earn 3% when
              a person signup with your link for life
            </p>
            <button class="copy-link">
              <span class="link"> https://thegainer.com/r/GiftVIM </span>
              <i class="fa-regular fa-copy"></i>
            </button>
            <div class="social-btns">
              <a
                href="https://twitter.com/intent/tweet?url=https://thegainer.com/r/GiftVIM&text=Increase%20you%20social%20media%20presence%20at%20the%20cheapest%20price%20possible%20by%20visiting"
                target="_blank"
                rel="noopener noreferrer"
                class="share"
              >
                <i class="fa-brands fa-twitter"></i>
              </a>
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=https://thegainer.com/r/GiftVIM"
                target="_blank"
                rel="noopener noreferrer"
                class="share"
              >
                <i class="fa-brands fa-facebook"></i>
              </a>
            </div>
          </div>
        </sweet-modal>
      </div>
    </div>
    <div class="transactions">
      <span class="transactions-head">Transactions</span>
      <div class="scroll-section">
        <div
          v-for="item in [11, 12, 13, 14, 15, 16]"
          :key="item"
          class="list-item"
        >
          <div class="left">
            <i class="fa-solid fa-plus"></i>
            <div class="main-text">
              <span class="top-up-text">Referal Bonus</span>
              <span class="method">Enyoghasim</span>
            </div>
          </div>
          <div class="right">
            <span class="amount">₦ 4,500</span>
            <span class="date">Mar 07, 2022</span>
          </div>
        </div>
        <div v-for="item in [1, 2, 3, 4, 5, 6]" :key="item" class="list-item">
          <div class="left">
            <i class="fa-solid fa-minus"></i>
            <div class="main-text">
              <span class="top-up-text">Withdrawal</span>
              <span class="method">Balance</span>
            </div>
          </div>
          <div class="right">
            <span class="amount">₦20</span>
            <span class="date">Mar 07, 2022</span>
          </div>
        </div>
        <div v-for="item in [341, 1112, 1113]" :key="item" class="list-item">
          <div class="left">
            <i class="fa-solid fa-minus"></i>
            <div class="main-text">
              <span class="top-up-text">Withdrawal</span>
              <span class="method">Bank</span>
            </div>
          </div>
          <div class="right">
            <span class="amount">₦20</span>
            <span class="date">Mar 07, 2022</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Referrals",
  metaInfo: {
    title: "The Gainer",
    titleTemplate: "%s | Referrals",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
